<template>
  <LayoutVerticalCore>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <component 
        :is="isNexos ? 'NexosNavbar' : 'Navbar'"
        :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

  </LayoutVerticalCore>
</template>

<script>
// import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue';
// import Navbar from '../components/Navbar.vue';

export default {
  name: 'LayoutVertical',

  components: {
    // LayoutVertical,
    // Navbar,
    LayoutVerticalCore: () => import('@core/layouts/layout-vertical/LayoutVerticalCore.vue' /* webpackChunkName: "LayoutVerticalCore" */),
    Navbar: () => import('@/layouts/components/Navbar.vue' /* webpackChunkName: "Navbar" */),
    NexosNavbar: () => import('@/layouts/components/NexosNavbar.vue' /* webpackChunkName: "NexosNavbar" */),
  },

  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.collective;
    },
    communitySlug() {
      return this.$route.params.communityId
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos' || this.mainSpace?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
    },
  }
};
</script>
